import React, { Component } from 'react';
import 'antd/dist/antd.css';
import '../App.css';

export default class Logout extends Component {
      render() {
        return (
          <div></div>
        );
      }
}